/**
 *
 */
.Toolbar {
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    height: 100%;
    position: sticky;
    border-radius: 0px;
}

.toolbar-filters {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 1rem 0;
}

/* Fix toolbar when it's in a small screen */
@media (max-width: 768px) {
    .toolbar-filters-search {
        flex-direction: column;
        padding: 1.5rem 0;
    }
}
