@import 'src/styles/media';

.forgot-password-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 0px;

    @include sm {
        padding: 32px;
    }

    .forgot-password-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .MuiPaper-root {
            width: 100%;
            max-width: 430px;
            border-radius: 8px;


            .MuiCardContent-root {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 32px;

                .title {
                    margin-top: 16px;
                    margin-bottom: 32px;
                }

                .divider {
                    margin-top: 24px;
                    margin-bottom: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        margin-left: 8px;
                        margin-right: 8px;
                        font-weight: 700;
                    }
                    .MuiDivider-root {
                        width: 32px;
                    }
                }

                .login-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 32px;
                    padding-bottom: 10px;
                }
                .create-account-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 10px;
                    padding-bottom: 24px;
                }



            }
        }
    }
}

