@import 'src/common/ui-kit/styles/media.scss';

.MapElementList {
    /* Base Styling of map */
    .grid_column .placeholder {
        max-width: 100%;
    }
    position: relative;
    padding: 2.4rem;
    overflow: hidden;
    width: 100%;

    gm-style-iw-c {
        box-shadow: none !important;
    }
    .gm-style-iw-t > div {
        max-width: auto !important;
    }
    .gm-style-iw {
        z-index: 1;
        padding: 0;
        .gm-style-iw-d {
            overflow-y: auto !important;
            overflow-x: hidden !important;
            max-width: 100% !important;
        }

        .MapElementCardWrapper {
            margin-bottom: 0;
            margin-right: 0;
        }
        button[title='Close'].gm-ui-hover-effect {
            width: 26px !important;
            height: 26px !important;
            padding: 6px !important;
            margin: 0 !important;
            top: 0 !important;
            right: 0 !important;
            opacity: 1;
            align-items: center;
            display: inline-flex !important;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            > img {
                margin: 0 !important;
            }
        }
    }
}
/* End of base Styling */

.MapElementList__Map {
    z-index: 1100;
    position: fixed;
    bottom: 0;
    // PageSimple Header + PageSimple Toolbar + Layout Toolbar
    @include md {
        height: 100vh;
        width: calc(60% - 4.8rem);
    }
    @include xl {
        width: calc(50% - 4.8rem);
    }
    & .FixedMap,
    & .LoadingMap {
        width: 100%;
        height: 100vh;
    }

    & .FixedMap {
        > div {
            position: absolute;
            width: 100%;
            height: 100% !important;
            > div {
                height: 100% !important;
            }
        }
    }

    & .LoadingMap {
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
            fill: rgba($color: #000000, $alpha: 0.8) !important;
            display: flex;
        }
    }
}

.MapElementListWrapperMap {
    display: none;
    @include md {
        display: block;
        width: calc(40% - 4.8rem);
    }
    @include xl {
        width: calc(50% - 4.8rem);
    }
}
.MapElementListWrapper {
    width: 100%;
}
