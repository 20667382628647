.forgot-password-success-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 32px;
    .forgot-password-success-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .MuiPaper-root {
            width: 100%;
            max-width: 384px;
            border-radius: 8px;

            .MuiCardContent-root {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 32px;

                .icon-container {
                    margin: 32px;
                    .icon {
                        font-size: 96px;
                    }
                }

                .title {
                    text-align: center;
                    margin-bottom: 16px;
                }

                .description {
                    text-align: center;
                    margin-bottom: 16px;
                    width: 100%;
                }
            }
        }
    }
}
