.MuiOutlinedInput-root {
    fieldset {
        span {
            font-size: 1.35rem;
        }
    }
}
.MuiOutlinedInput-root:hover {
    fieldset {
        border-width: 2px;
    }
}
